import React from 'react';
import { Helmet } from 'react-helmet';

import SiteHeader from '../../../components/SiteHeader/type4';
import SectionHeader from '../../../components/SectionHeader';
import KinetikFeature from '../../../components/KinetikFeature';
import ConsultantFeature from '../../../components/ConsultantFeature';
import Footer from '../../../components/Footer';
import ProjectFeature from '../../../components/ProjectFeature';
import CTA from '../../../components/CTA';
import SiteNavigation from '../../../components/SiteHeader/navigation';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// @ts-ignore
import MidtkraftLogo from '../../../../static/Logos/midtkraft.svg';

// @ts-ignore
import KinetikNettGif from '../../../../static/KinetikNett/kinetiknett.gif';

// @ts-ignore
import KinetikNettMobileGif from '../../../../static/KinetikNett/mobile.gif';

// @ts-ignore
import KinetikNettAlertGif from '../../../../static/KinetikNett/alert.gif';

// @ts-ignore
import AktiverHANVelkommen from '../../../../static/AktiverHAN/Velkommen.svg';

// @ts-ignore
import AktiverFeatures1 from '../../../../static/AktiverHAN/Features1.svg';

// @ts-ignore
import AktiverFeatures2 from '../../../../static/AktiverHAN/Features2.svg';

import LogoCloud from '../../../components/LogoCloud';

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon, CheckIcon } from '@heroicons/react/outline'

const tiers = [
    {
        name: 'AktiverHAN',
        href: '/om/#kontakt',
        priceMonthly: 2250,
        description: 'Dette gir tilgang til selbetjeningsløsning for kundene slik at de selv kan aktivere HAN port.',
        features: [
            'Integrert mot HES system',
            'Integrert på selskapets egne nettsider',
            'Automatisk rapport til KIS system',
            'Skreddersy vilkår med selskapets egne',
        ],
    },
    {
        name: 'Bulkoppdatering',
        href: '/om/#kontakt',
        priceMonthly: 350,
        description: 'Modulen gjør det mulig for de ansatte i selskapet å deaktivere/aktivere flere porter samtidig. ',
        features: [
            'Ubegrenset aktiveringer',
            'Ubegrenset deaktiveringer',
        ],
    },
    {
        name: 'AUTO. deaktivering',
        href: '/om/#kontakt',
        priceMonthly: 750,
        description: 'Automatisk deaktivering gjør at porter stenges automatisk når det registreres eierskifte på bolig.',
        features: [
            'Integrasjon mot KIS system',
        ],
    },
]

const overviewFeatures = [
    {
        id: 1,
        name: 'Selvbetjent aktivering',
        description:
            'Med et enkelt grensesnitt som kan integreres mot egne nettsider, kan bruker selv aktivere HAN porten. Operasjonen foregår med sikker innlogging via Bank-ID og er gjort på under 1 minutt.  ',
        icon: GlobeAltIcon,
    },
    {
        id: 2,
        name: 'Automatisk deaktivering',
        description:
            'Har dere husket å deaktivere HAN port ved eierskifte av bolig? Med automatisk dektivering skjer dette automatisk når eierskifte gjennomføres på en bolig i nettet ditt. ',
        icon: ScaleIcon,
    },
    {
        id: 3,
        name: 'Bulk aktivering/dektivering',
        description:
            'Med aktiver HAN er det mulig å gjennomføre flere aktiveringer/deaktiveringer i en og samme operasjon.  ',
        icon: LightningBoltIcon,
    },
]
const alertFeatures = [
    {
        id: 1,
        name: 'Bulk operasjoner',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: AnnotationIcon,
    },
    {
        id: 2,
        name: 'Noe annet',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: MailIcon,
    },
    {
        id: 3,
        name: 'Noe annet',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: MailIcon,
    },
]

function AktiverHAN({ props }) {
    return (
        <div className="bg-gray-50">
            <Helmet>
                <title>Convivial | AktiverHAN</title>
                <link rel="canonical" href="https://convivial.no/tjenester/aktiverhan" />
                <meta name="description" content="HAN port aktivering/deaktivering med AktiverHAN" />
                <script async defer data-domain="convivial.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <main>
                <SiteNavigation bgColor="bg-gray-50" />
                <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 mb-16">
                    <div className="sm:text-center">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl font-header">
                            <span className="block">HAN port aktivering/deaktivering med</span>{' '}
                            <span className="block text-aktiverhan">AktiverHAN</span>
                        </h1>
                        <p className="mt-3 max-w-md sm:mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                            Tjenesten gir mulighet for en selvbetjent løsning hvor forbruker selv åpner HAN-port. Dette frigjør intern tid som kan brukes på andre viktige oppgaver.
                        </p>
                    </div>
                </div>

                <div className="bg-gray-50">
                    <div className="relative overflow-hidden">
                        <div className="relative">
                            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                                <div className="flex-1" />
                                <div className="flex-1 w-full bg-white" />
                            </div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-6">

                                <img
                                    className="relative rounded-lg w-full"
                                    src={AktiverHANVelkommen}
                                    alt="Kinetik nett interface"
                                />
                            </div>
                        </div>
                    </div>
                    <LogoCloud bgColor="bg-white" product={['kinetiknett']} />
                </div>

                <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
                    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="relative">
                            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Har dere kontroll på aktivering og deaktivering av HAN&nbsp;porten?
                            </h2>
                            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                                Med en eksplosiv vekst på tilbydere som ønsker å koble til utstyr i HAN port for å hjelpe forbrukere med strømstyring i hjemmet, medfører dette økt press på nettselskapene.
                                Med Aktiver HAN automatiseres prosessen som gjør at ansatte og kunder får en mye bedre brukeropplevelse.
                            </p>
                        </div>

                        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                            <div className="relative">
                                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                    Full oversikt
                                </h3>
                                <p className="mt-3 text-lg text-gray-500">
                                    Tjenesten sørger for en enkel og rask brukeropplevelse og frigjør intern tid til viktigere oppgaver.
                                </p>

                                <dl className="mt-10 space-y-10">
                                    {overviewFeatures.map((item) => (
                                        <div key={item.id} className="relative">
                                            <dt>
                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-aktiverhan text-white">
                                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                            </dt>
                                            <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>

                            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                                <img
                                    className="relative mx-auto"
                                    width={490}
                                    src={AktiverFeatures1}
                                    alt=""
                                />
                            </div>
                        </div>


                    </div>
                </div>



                <div className="bg-kinetik-dark">
                    <div className="pt-12 sm:pt-16 lg:pt-24">
                        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                                <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">PRISER</h2>
                                <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                                    Ingen skjulte&nbsp;priser
                                </p>
                                <p className="text-xl text-gray-300">
                                    Skjulte priser eller pris på forespørsel er utrolig irriterende. Vi ønsker å være så transparente som mulig, derfor synliggjør vi hva de faste&nbsp;kostnadene.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 bg-gray-50 sm:mt-12 lg:mt-16">
                        <div className="relative">
                            <div className="absolute inset-0 h-3/4 bg-kinetik-dark" />
                            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0 ">
                                    {tiers.map((tier) => (
                                        <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                                <div>
                                                    <h3
                                                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-aktiverhan text-white"
                                                        id="tier-standard"
                                                    >
                                                        {tier.name}
                                                    </h3>
                                                </div>
                                                <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                                                    {tier.priceMonthly}
                                                    <span className="ml-1 text-2xl font-medium text-gray-500">kr/mnd</span>
                                                </div>
                                                <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                                                <ul className="space-y-4">
                                                    {tier.features.map((feature) => (
                                                        <li key={feature} className="flex items-start">
                                                            <div className="flex-shrink-0">
                                                                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                                                            </div>
                                                            <p className="ml-3 text-base text-gray-700">{feature}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="rounded-md shadow">
                                                    <a
                                                        href={tier.href}
                                                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                                                        aria-describedby="tier-standard"
                                                    >
                                                        Ta kontakt
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
                            <div className="max-w-md mx-auto lg:max-w-5xl">
                                <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                                    <div className="flex-1">
                                        <div>
                                            <div className="flex justify-between">
                                                <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800 mb-4">
                                                    Etablering av tjenesten
                                                </h3>
                                            </div>
                                            <div>
                                                <span className="font-semibold text-gray-900 text-4xl mr-2">25 000</span>kr/engangsbeløp
                                            </div>
                                        </div>
                                        <div className="mt-4 text-lg text-gray-600">
                                            Etableringen inneholder konfigurasjon for håndtering av nettselskapet, tilpasning av mal mot KIS-system, samt tilpasning av nettselskapets avtalevilkår for aktivering av HAN porten.
                                        </div>
                                        <div className="text-sm text-gray-400 mt-2">
                                            * Det påløper transaksjonskost for hver aktivering av HAN-port gjort med Bank-ID. 5,- pr. transaksjon
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CTA
                    demo={true}
                    header="Fortsatt nysgjerrig?"
                    subHeader="Book en demo eller kontakt&nbsp;oss."
                    bgColor="bg-gray-50"
                    contactButtonColor="bg-aktiverhan"
                    demoButtonColor="text-aktiverhan"
                />

            </main>
            <Footer />
        </div>
    );
}

export default AktiverHAN;
